import "../../../../../wp/wp-includes/css/dist/block-library/style.min.css";
import "tiny-slider/src/tiny-slider.scss";

import PhotoSwipe from "photoswipe/dist/photoswipe.js";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default.js";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

let parent = document.querySelectorAll(".blocks-gallery-grid");

for (let iParent = 0; iParent < parent.length; iParent ++) {
    if(parent[iParent]) {
        let child = Array.from(parent[iParent].querySelectorAll('.blocks-gallery-item'));

        parent[iParent].setAttribute("data-photoswipe", '');

        child.forEach((item, i) => {
            let a = child[i].getElementsByTagName('a');
            let img = child[i].getElementsByTagName('img');

            let width = 0;
            let height = 0;
            let mediumUrl = '';
            let largeUrl = '';

            for (let iChild = 0; iChild < a.length; iChild ++) {
                largeUrl = a[iChild].getAttribute('href');

                if(a[iChild]) {
                    a[iChild].classList.add('photo-link');
                    a[iChild].setAttribute('data-large-url', largeUrl);
                }
            }
            for (let iChild = 0; iChild < img.length; iChild ++) {
                width = img[iChild].getAttribute('width');
                height = img[iChild].getAttribute('height');
                mediumUrl = img[iChild].getAttribute('src');

                if(a[iChild]) {
                    a[iChild].setAttribute('data-width', width);
                    a[iChild].setAttribute('data-height', height);
                    a[iChild].setAttribute('data-medium-url', mediumUrl);
                }
            }
        });
    }
}

function initializePhotoSwipe()
{
    Array
        .from(document.querySelectorAll("[data-photoswipe]"))
        .forEach(gallery => initializeGallery(gallery));
}

function initializeGallery(container)
{
    const items = [];
    const pswp = document.querySelector(".pswp");
    const photoLinks = container.querySelectorAll(".photo-link");

    const openGallery = (evt, launchFrom) =>
    {
        const gallery = new PhotoSwipe(pswp, PhotoSwipeUI_Default, items, {

            index: launchFrom,
            showHideOpacity: true,

            getThumbBoundsFn(index)
            {
                const photo = photoLinks[index];
                const {top, left, height, width} = photo.getBoundingClientRect();

                return {
                    x: left,
                    y: top + (document.scrollingElement || document.documentElement).scrollTop,
                    w: width,
                    h: height
                };
            }

        });
        gallery.init();

        evt.preventDefault();
        evt.stopPropagation();
    };

    photoLinks.forEach((photo, index) =>
    {
        const height = parseInt(photo.dataset.height);
        const width = parseInt(photo.dataset.width);
        const src = photo.dataset.largeUrl;

        items.push({
            src: src,
            w: width,
            h: height
        });

        photo.addEventListener("click", evt => openGallery(evt, index));
    });
}

window.addEventListener("load", () =>
{
    initializePhotoSwipe();
});
